export const sectionList = [
  'paragraphWithImage',
  'headerLogoWithParagraph',
  'contentlistWithImage',
  'headerImageWithText',
  'corousel',
  'introCards',
  'paragraphWithImageAndIcons',
  'headerWithBackgroungImage',
  'internalNavigation',
  'contentListWith3Items',
  'titleWithParagraph',
  'internalNavigation',
  'mapWithMarkers',
  'locationDataTable',
  'contactUs',
  'thankYouPage',
  'contentPressRelease'
];

