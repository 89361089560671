import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import NavigatioBar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import * as Styled from './commonPageTemplateStyles';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../../styles/GlobalStyles';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { sectionList } from '../../constants/queryFragments';
import Fevicon from '../../images/fevicon.png';
import '../../styles/style.css';

const CommonPageTemplate=({data,location})=>{
    React.useEffect(() => {
        if (!location?.hash && typeof window !== 'undefined')
        {window.scrollTo(0, 0);}
        }, []);

    const sectionsArray = data?.allContentfulSolEcolution?.edges[0]?.node?.sections;
    const path = location?.pathname?.split('/')[1];
    const title = data?.allContentfulSolEcolution?.edges[0].node.title || 'Welcome';
    
    return <>
    <GlobalStyles/>
    <Helmet>
        <title>
            {title}
        </title>
        <link rel="icon" href={Fevicon} type="image/png" />
        {data?.allContentfulSolEcolution?.edges[0]?.metadata && (
            <meta
            name="description"
            content={data?.allContentfulSolEcolution?.edges[0]?.metadata}
            ></meta>
        )}
        {data?.allContentfulSolEcolution?.edges[0]?.node?.hasIndex === false && <meta name="robots" content="noindex" />}
    </Helmet>
    <NavigatioBar activeLink={path}/>
    <Styled.CommonPageContainer className={ path === 'in-the-media' ? 'fixed' : ''}>
    <>
    {sectionsArray &&
        sectionsArray?.map((section, index) => {
        if (
            section?.type &&
            sectionList?.includes(section?.type) 
        ) {
            const SectionComponent = loadable(() =>
            import(`../../components/${section?.type}/${section?.type}`)
            );
            return (
            <SectionComponent
                key={index}
                sectionData={section}
            />
            );
        }
        return <></>;
        })}
    </>
    </Styled.CommonPageContainer>
    <Footer/>
    </>
};

export default CommonPageTemplate;

export const pageQuery = graphql`
query allCommonPagesQuery(
$pageIdentifier: String!
$contentfulContent: Boolean!
$contentfulContentList: Boolean!
$contentfulTextWithImage: Boolean!
$contentfulListImagesWithPath: Boolean!
$contentfulListTextWithPath: Boolean!
) {
    allContentfulSolEcolution(
    filter: { identifier: { type: { eq: $pageIdentifier } }, node_locale: {eq: "en-US"} }
) {
        edges {
            node {
                name
                identifier {
                type
                }
                slug
                title
                node_locale
                hasIndex
                sections {
                    ...PageContent @include(if: $contentfulContent)
                    ...ContentList @include(if: $contentfulContentList)
                    ...TextWithImage @include(if: $contentfulTextWithImage)
                    ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
                    ...ListTextWithPath @include(if: $contentfulListTextWithPath)
                }
            }
        }
    }
}
`;
